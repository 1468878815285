import React from 'react';
import Scroll from '../common/Scroll';

const Introduction = () =>
  <section id="intro" className="wrapper style1 fullscreen fade-up">
    <div className="inner">
      <h1>Paul Clegg</h1>
      <p>
        CTO @ <a href="https://www.raildiary.com" rel="nofollow" target="_blank">Raildiary</a>,
        developer and technology enthusiast.
      </p>
      <ul className="actions">
        <li>
            <a href="mailto:hello@paulclegg.me" className="button">
              Contact me
            </a>
        </li>
      </ul>
    </div>
  </section>


export default Introduction;
